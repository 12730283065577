import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';
import { useCookies } from 'react-cookie';

import api from '../../../services/api';

import { IUsers } from '../RegisteredUsers';

import {
  Container,
  CardContent,
  CardHeader,
  InfoSections,
  Icons,
  TextSection,
  TitleSection,
  UserName,
} from './styles';
import Modal from '../../../components/custom/Modal';
import CustomButton from '../../../components/custom/Button';

interface Props {
  user: IUsers;
}

const UserCard: React.FC<Props> = ({ user }) => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [sucessModal, setSucessModal] = useState<boolean>(false);
  const [hideCard, setHideCard] = useState<string>('flex');

  const [cookies] = useCookies(['gun']);

  const history = useHistory();

  function openWarningDelete() {
    setConfirmModal(true);
  }

  function deleteUser(id: number) {
    setConfirmModal(false);
    api.delete(`authenticate/user/delete/${id}/`);
    setHideCard('none');
    setSucessModal(true);
  }

  return (
    <>
      {confirmModal ? (
        <Modal text="Tem certeza que deseja excluir o usuário?" type="warning">
          <CustomButton
            text="Não excluir"
            clickFunction={() => setConfirmModal(false)}
            color="var(--white-text)"
            border="1px solid var(--primary-color)"
            background="#1C3359"
          />
          <CustomButton
            text="Excluir"
            clickFunction={() => deleteUser(user.id)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      {sucessModal ? (
        <Modal text="Usuário excluído com sucesso!" type="sucess">
          <CustomButton
            text="OK"
            clickFunction={() => setSucessModal(false)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      <Container style={{ display: hideCard }}>
        <CardHeader>
          <UserName>{user.name}</UserName>
          <Icons>
            <RiEdit2Line
              size={22}
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => {
                history.push(`/edit-users/${user.id}`);
              }}
            />
            <RiDeleteBin2Line
              size={22}
              data-testid="deleteuser"
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => openWarningDelete()}
            />
          </Icons>
        </CardHeader>
        <CardContent>
          <InfoSections>
            <TitleSection>E-mail</TitleSection>

            <TextSection>{user.email}</TextSection>
          </InfoSections>
        </CardContent>
      </Container>
    </>
  );
};

export default UserCard;
