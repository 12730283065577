import React, { useState, useEffect } from 'react';
import { RiSaveLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import CommonStructurePage from '../../../CommonStructurePage';

import {
  Container,
  Title,
  Descriptions,
  Input,
  FormData,
  WrapperInput,
  Wrapper,
  WrapperButtons,
  WrapperContainer,
  Select,
  Option
} from './styles';

import Button from '../../../../components/custom/Button';
import Modal from '../../../../components/custom/Modal';

const RegisterCams: React.FC = () => {
  const [device, setDevice] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [ipAdress, setIpAdress] = useState<string>('');
  const [macAdress, setMacAdress] = useState<string>('');
  const [rtsp, setRtsp] = useState<string>('');
  const [isAlive, setIsAlive] = useState<string>('');
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);
  const [dataSelect, setDataSelect] = useState<any>();


  const history = useHistory();

  useEffect(() => {
    api.get('devices/').then(response => {
      setDataSelect(response.data)
    })
  }, [])

  const handleRegister = () => {
    let tempAlive;

    if(isAlive === '0'){
      tempAlive = false;
    }
    else {
      tempAlive = true;
    }

    const data = {
      name,
      device_id: device,
      ip_address: ipAdress,
      mac_address: macAdress,
      is_alive: tempAlive,
      rtsp
    };
    api
      .post('cameras/', data)
      .then(() => setSucessRegister(true))
      .catch(() => setErroRegister(true));
  };

  return (
    <CommonStructurePage>
      <WrapperContainer>
      <Container>
        <Title>Cadastrar uma câmera</Title>
        <Descriptions>Preencha os campos a seguir:</Descriptions>
        <FormData>
          <Wrapper>
            <WrapperInput>
              <Descriptions>Nome:</Descriptions>
              <Input
                type="text"
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Ativa</Descriptions>
              <Select id="select-state" onChange={(e: any) => setIsAlive(e.target.value)}>
                <Option value="" selected>
                  Choose here
                </Option>
                <Option value="0">
                  falso
                </Option>
                <Option value="1">
                  verdadeiro
                </Option>
              </Select>
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Endereço Ip:</Descriptions>
              <Input
                type="text"
                onChange={(e) => setIpAdress(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Endereço Mac:</Descriptions>
              <Input
                type="text"
                onChange={(e) => setMacAdress(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Rtsp:</Descriptions>
              <Input
                type="text"
                onChange={(e) => setRtsp(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Dispositivo:</Descriptions>
              <Select id="select-state" onChange={(e: any) => setDevice(e.target.value)}>
              <Option value="" selected>
                Choose here
              </Option>
              {dataSelect &&
                dataSelect.map((item: any) => {
                  return (
                    <Option value={item.id}>
                      {item.device}
                    </Option>
                  );
                })}
            </Select>
            </WrapperInput>
          </Wrapper>
          <WrapperButtons>
            <Button
              text="Cancelar"
              color="#040E21"
              background="#fff"
              clickFunction={() => history.push('/registered-cams')}
            />
            <Button
              text="Salvar"
              color="#fff"
              Icon={RiSaveLine}
              background="#040E21"
              clickFunction={handleRegister}
            />
          </WrapperButtons>
        </FormData>
        {sucessRegister ? (
          <Modal type="sucess" text="Câmera cadastrada com sucesso!">
            <Button
              text="OK"
              color="#040E21"
              background="#FFFFFF"
              className="button-cancel"
              clickFunction={() => {
                setSucessRegister(false);
                history.push('/registered-cams');
              }}
            />
          </Modal>
        ) : (
          <></>
        )}
        {erroRegister ? (
          <Modal type="error" text="Falha ao cadastrar câmera!">
            <Button
              text="OK"
              color="#FFFFFF"
              background="#1C3359"
              className="button-cancel"
              clickFunction={() => setErroRegister(false)}
            />
          </Modal>
        ) : (
          <></>
        )}
      </Container>
      </WrapperContainer>
    </CommonStructurePage>
  );
};

export default RegisterCams;
