import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

:root{
  --header-background: #152744;
  --white-text: #ffffff;
  --button-logout: #1C3359;
  --sidebar: #1C3255;
  --background: #040E21;
  font-family: 'Noto Sans', sans-serif;
}

*, html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
}

button, input {
  border: 0;
  outline: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: var(--white-text);
}

`;
