import React from 'react';
import { Container } from './styles';
import CommonStructurePage from '../CommonStructurePage/index';
import Map from '../../components/Map';

const HomePage: React.FC = () => {
  return (
    <CommonStructurePage>
      <Container>
        <Map />
      </Container>
    </CommonStructurePage>
  );
};

export default HomePage;
