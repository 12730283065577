import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/custom/Button';
import CommonStructurePage from '../../CommonStructurePage';

import { Container, Wrapper } from './styles';

const RegistersMenu: React.FC = () => {
  const history = useHistory();

  return (
    <CommonStructurePage>
      <Container>
        <Wrapper>
          <Button
            text="Locais"
            color="#fff"
            background="#040E21"
            clickFunction={() => history.push('/registered-places')}
          />
          <Button
            text="Dispositivos"
            color="#fff"
            background="#040E21"
            clickFunction={() => history.push('/registered-devices')}
          />
          <Button
            text="Câmeras"
            color="#fff"
            background="#040E21"
            clickFunction={() => history.push('/registered-cams')}
          />
          <Button
            text="Usuários"
            color="#fff"
            background="#040E21"
            clickFunction={() => history.push('/registered-users')}
          />
          <Button
            text="Persistência de dados"
            color="#fff"
            background="#040E21"
            clickFunction={() => history.push('/persist-data')}
          />
        </Wrapper>
      </Container>
    </CommonStructurePage>
  );
};

export default RegistersMenu;
