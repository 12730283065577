import styled from 'styled-components';

interface Props {
  expand: boolean;
}

export const Container = styled.div<Props>`
  width: ${(props) => (props.expand ? '100vw' : '0')};
  height: calc(100vh - 60px);

  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: var(--sidebar);

  overflow-x: hidden;

  z-index: 10;

  @media (min-width: 1028px) {
    width: ${(props) => (props.expand ? '400px' : '0')};

    position: relative;
  }

  transition: width 0.3s;

  .active-route {
    background: ${(props) => (props.expand ? '#EFF1F5' : '#0000')};
    color: #040e21;
    border-left: ${(props) =>
      props.expand ? '3px solid #319ADB' : '3px solid #0000'};
    border-right: ${(props) =>
      props.expand ? '3px solid #0000' : '3px solid #319ADB'};
  }
`;

export const MenuHamburger = styled.div<Props>`
  width: ${(props) => (props.expand ? '98%' : 'auto')};
  position: ${(props) => (props.expand ? 'relative' : 'fixed')};
  top: ${(props) => (props.expand ? '0' : '12px')};
  left: ${(props) => (props.expand ? '0' : '12px')};

  z-index: 99;

  padding: 20px;
  margin-bottom: 10vh;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 4px;

  background: #fff;

  cursor: pointer;

  @media (min-width: 1028px) {
    width: ${(props) => (props.expand ? '92%' : 'auto')};
    position: relative;
    left: 0;
    top: 0;

    padding: 18px 0 32px 0;
    margin-bottom: 32px;

    border-radius: 0;

    background: none;
  }

  img {
    width: ${(props) => (props.expand ? '100px' : '0px')};

    padding-right: ${(props) => (props.expand ? '10px' : '0px')};
  }

  .empty-div {
    width: 10px;
  }
`;

export const FooterLogo = styled.img<Props>`
  width: 72px;

  display: ${(props) => (props.expand ? 'block' : 'none')};

  position: absolute;
  bottom: 12px;
`;

export const AlertTitle = styled.h5`
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
  font-weight: 900;

  background: var(--sidebar);
  color: var(--white-text);
`;

export const AlertBox = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-around;

  border-top: 1px solid var(--header-background);

  gap: 5px;

  cursor: pointer;
`;

export const AlertImage = styled.div`
  max-width: 30%;
  height: 75px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;

  .alert-image {
    width: auto;
    height: 50px;
  }

  @media (min-width: 768px) and (min-height: 1024px) {
    max-width: 30%;
    height: 75px;

    .alert-image {
      width: 100%;
      height: 65px;
    }
  }
`;

export const AlertTexts = styled.div`
  width: 70%;
  height: auto;
`;

export const AlertCam = styled.p`
  font-size: 18px;
  color: var(--white-text);
  line-height: 1;
`;

export const AlertWeapon = styled.p`
  font-size: 15px;
  color: var(--white-text);
`;

export const AlertAdress = styled.p`
  font-size: 12px;
  color: var(--white-text);
`;

export const Wrapper = styled.div`
  width: 80%;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;
