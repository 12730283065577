import styled from 'styled-components';
import { Link } from 'react-router-dom';
import bgImage from '../../../assets/background.png';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  overflow: hidden;
  background: var(--background);
  background-image: url(${bgImage});
`;

export const LeftMain = styled.div`
  display: none;

  @media (min-width: 980px) {
    width: 70%;
    height: 100vh;
    padding-right: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #fafafa;
    color: var(--background);

    clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
  }
`;

export const PrimerLogo = styled.img`
  width: 200px;
`;

export const WelcomeText = styled.h1`
  font-size: 64px;
`;

export const SubWelcomeText = styled.p`
  font-size: 22px;
`;

export const RightMain = styled.div`
  width: 100%;
  height: 100vh;
  margin-left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 32px;

  @media (min-width: 980px) {
    width: 50%;
    height: 100vh;
    margin-left: -10%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 32px;
  }
`;

export const CirclePeople = styled.div`
  width: 180px;
  height: 180px;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #e0e0e0;
`;

export const InputsContainer = styled.div`
  width: 320px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
`;

export const InputDiv = styled.div`
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 62px;

  border-radius: 12px;

  background: #e0e0e0;
  color: var(--background);

  padding: 12px;

  ::placeholder {
    color: var(--background);
    font-weight: 600;
    opacity: 1;
  }
`;

export const LoginButton = styled.button`
  width: 320px;
  height: 62px;

  border-radius: 12px;

  background: #e0e0e0;
  color: var(--background);
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s;

  &:hover {
    background: #0000;
    color: #e0e0e0;
    border: 1px solid #e0e0e0;
  }
`;

export const LoginOptions = styled.div`
  width: 103%;
  margin-left: -3%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RememberContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RememberText = styled.p`
  font-size: 14px;
  color: #e0e0e0;
`;

export const ForgotPasswordText = styled(Link)`
  background: none;

  font-size: 14px;
`;
