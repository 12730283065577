import React from 'react';
import { IconType } from 'react-icons';
import { Container } from './styles';

interface Props {
  title: string;
  urlPath: string;
  Icon: IconType;
  expand: boolean;
  testid?: string;
}

const SimpleMenuRedirect: React.FC<Props> = ({
  testid,
  title,
  urlPath,
  Icon,
}) => {
  return (
    <Container>
      <a href={urlPath} className="active-route">
        <Icon size={22} />
        <h3>{title}</h3>
      </a>
    </Container>
  );
};

export default SimpleMenuRedirect;
