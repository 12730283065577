import React from 'react';
import EventBox from '../../../components/EventBox';
import formatToInputDate from '../../../utils/formatToInputDate';

import { Tr, Td } from '../styles';

const EventTrBox: React.FC<any> = ({ item }) => {
  const [callEvent, setCallEvent] = React.useState<boolean>(false);
  const handleCallEvent = () => {
    setCallEvent(!callEvent);
  };
  return (
    <>
      <EventBox event={item} visible={callEvent} setVisible={handleCallEvent} />
      <Tr onClick={handleCallEvent} style={{ cursor: 'pointer' }}>
        <Td>{item.type_weapon}</Td>
        <Td>{item.place}</Td>
        <Td>{item.camera}</Td>
        <Td>{item.accuracy}</Td>
        <Td>{formatToInputDate(item.datetime)}</Td>
      </Tr>
    </>
  );
};

export default EventTrBox;
