import React, { useEffect, useState } from 'react';
import { Container, SelectGrid, ExternalContainer } from './styles';
import CommonStructurePage from '../CommonStructurePage/index';
import CameraContainer from './CameraContainer';

const CamPage: React.FC = () => {
  const [gridValue, setGridValue] = useState<any>();
  const [countCameras, setCountCameras] = useState<any>([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  useEffect(() => {
    console.warn(localStorage.getItem('gridValue'))
    setGridValue(
      localStorage.getItem('gridValue')
        ? localStorage.getItem('gridValue')
        : '1fr 1fr 1fr'
    );
  }, []);

  useEffect(() => {
    setCameraCount(gridValue);
  }, [gridValue]);

  function handleGridValue(e: any) {
    setGridValue(e.currentTarget.value);

    localStorage.setItem('gridValue', e.currentTarget.value);

    setCameraCount(e.currentTarget.value);
  }

  function setCameraCount(val: string) {
    setCountCameras(undefined);

    if (val === '1fr') {
      setCountCameras([0]);
    } else if (val === '1fr 1fr') {
      setCountCameras([0, 0, 0, 0]);
    } else if (val === '1fr 1fr 1fr') {
      setCountCameras([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    } else {
      setCountCameras([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }

  return (
    <CommonStructurePage>
      <ExternalContainer>
        <SelectGrid value={gridValue} onChange={handleGridValue}>
          <option value="1fr">1x</option>
          <option value="1fr 1fr">2x</option>
          <option value="1fr 1fr 1fr">3x</option>
          <option value="1fr 1fr 1fr 1fr">4x</option>
        </SelectGrid>
        <Container gridValue={gridValue}>
          {countCameras && countCameras?.map(() => <CameraContainer />)}
        </Container>
      </ExternalContainer>
    </CommonStructurePage>
  );
};

export default CamPage;
