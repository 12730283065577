import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 80vh;

  div > div {
    border-radius: 5px;
    gap: 5px;
  }
`;

interface ICord {
  lat: number;
  lng: number;
}

export const MapBox = styled.div`
  width: 100%;
  height: 80vh;

  background: var(--header-background);
`;
