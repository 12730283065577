import styled from 'styled-components';

interface ButtonStyleProps {
  color: string;
  background: string;
  border?: string;
}

export const Container = styled.button<ButtonStyleProps>`
  min-width: 120px;
  height: 35px;

  padding: 16px 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  color: ${({ color }) => color};
  background: ${({ background }) => background};
  border: ${({ border }) => border};
  border-radius: 4px;

  transition: all 0.4s;

  &:hover {
    background: ${({ color }) => color};
    color: ${({ background }) => background};

    padding: 16px 20px;
  }
`;

export const TextButton = styled.p`
  font-size: 12px;
  font-weight: 400;
`;
