import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { RiAddCircleLine, RiSearchLine } from 'react-icons/ri';

import Button from '../../../../components/custom/Button';
import CommonStructurePage from '../../../CommonStructurePage';
import api from '../../../../services/api';

import {
  Container,
  Title,
  SelectContainer,
  TitleContainer,
  Cards,
  ButtonContainer,
} from './styles';
import DevicesCard from '../DevicesCard';


export interface IDevices{
    id: number;
    device: string;
    place: string;
    cameras: [
      {
        id: number;
        ip: string;
        mac: string;
        camera: string;
        rtsp: string
      }
    ];
    lat: number;
    lng: number;
}

const RegisteredDevices: React.FC = () => {
  const [stateData, setStateData] = useState<any>([]);
  const [findDevices, setFindDevices] = useState<string>('');
  
  const history = useHistory();

  useEffect(() => {
    api.get('devices/').then((response) => {
      setStateData(response.data);
      console.log(response.data);
    });
  }, []);

  const handleFilterUsers = () => {
    let tempData: IDevices[] = stateData;


    if (findDevices !== '') {
      tempData = tempData.filter(({ device }) => device.includes(findDevices));
      setStateData(tempData);
    } else {
      api.get('devices/').then((response) => {
        setStateData(response.data);
      });
    }

  };

  return (
    <CommonStructurePage>
      <Container>
        <TitleContainer>
          <Title>Dispositivos cadastrados</Title>
        </TitleContainer>
        <TitleContainer>
          <SelectContainer>
            <TextField
              id="standard-basic"
              value={findDevices}
              onChange={(e) => setFindDevices(e.currentTarget.value)}
              style={{ width: '300px' }}
              placeholder="Buscar local"
              className="MuiInput-root"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearchLine size={18} color="#808495" />
                  </InputAdornment>
                ),
              }}
            />
          </SelectContainer>
          <ButtonContainer>
          <Button
              text="Filtrar"
              color="#fff"
              background="#1C3359"
              clickFunction={handleFilterUsers}
            />
            <Button
            text="Cadastrar novo"
            color="#fff"
            Icon={RiAddCircleLine}
            background="#1C3359"
            clickFunction={() => history.push('/register-devices')}
          />
          </ButtonContainer>
          
        </TitleContainer>

        <Cards>
          {stateData?.map((item: any) => (
            <DevicesCard devices={item} />
          ))}
        </Cards>
      </Container>
    </CommonStructurePage>
  );
};

export default RegisteredDevices;
