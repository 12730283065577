import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  RiCamera2Fill,
  RiCamera2Line,
  RiDeviceFill,
  RiDeviceLine,
} from 'react-icons/ri';

import { FaHome, FaCamera, FaChartLine, FaTable } from 'react-icons/fa';
import api from '../../../services/api';

import ComplexMenuItem from './ComplexMenuItem';
import SimpleMenuRedirect from './SimpleMenuRedirect';

import { Container, ButtonNavigate } from './styles';
import SimpleMenuItem from '../Alert/SimpleMenuItem';

interface IProps {
  expand: boolean;
}

interface IDevices {
  id: number;
  name: string;
  place: string;
  device: string;
}

interface ICameras {
  id: number;
  ip_address: string;
  mac_address: string;
  is_alive: boolean;
  devices: number;
  name: string;
}

const Sidebar: React.FC<IProps> = ({ expand }) => {
  const [devices, setDevices] = useState<IDevices[] | undefined>();
  const [cameras, setCameras] = useState<ICameras[] | undefined>();
  const Location = (urlPath: string) =>
    useLocation().pathname.includes(urlPath);

  useEffect(() => {
    api
      .get('devices/')
      .then((response) => {
        setDevices(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    api
      .get('cameras/')
      .then((response) => {
        setCameras(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <Container expand={expand}>
      <SimpleMenuItem expand title="Início" urlPath="/home" Icon={FaHome} />
      <SimpleMenuItem
        expand
        title="Câmeras"
        urlPath="/campage"
        Icon={FaCamera}
      />
      <ComplexMenuItem
        title="Dispositivos"
        urlPath="/devices"
        Icon={Location('/devices') ? RiDeviceFill : RiDeviceLine}
        expand={expand}
      >
        {devices &&
          devices.map((items) => (
            <a href={`/devices/${items.id}`} key={items.id}>
              <RiDeviceFill className="icons" />
              {items.device}
            </a>
          ))}
      </ComplexMenuItem>
      <ComplexMenuItem
        title="Câmeras Específicas"
        urlPath="/specify"
        Icon={Location('/specify') ? RiCamera2Fill : RiCamera2Line}
        expand={expand}
      >
        {cameras &&
          cameras.map((items) => (
            <a href={`/specify/${items.id}`} key={items.id}>
              <ButtonNavigate>
                <RiCamera2Fill className="icons" />
                {items.name}
              </ButtonNavigate>
            </a>
          ))}
      </ComplexMenuItem>
      <SimpleMenuItem expand title="Eventos" urlPath="/events" Icon={FaTable} />
      <SimpleMenuRedirect expand title="Metabase" urlPath={`https://metabase.${window.location.host}`} Icon={FaChartLine} />
    </Container>
  );
};

export default Sidebar;
