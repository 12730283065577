import styled from 'styled-components';

export const ExternalContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<any>`
  width: 100%;

  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;

  gap: 20px;

  transition: all 0.3s;

  @media (min-width: 980px) {
    display: grid;
    grid-template-columns: ${props => props.gridValue};
    grid-template-rows: auto;
    grid-gap: 15px;
    justify-items: center;
    justify-content: center;
  }
`;

export const SelectGrid = styled.select`
  background: #152744;
  border: none;

  min-height: 60px;
  max-height: 60px;

  margin-bottom: 12px;
  padding: 12px;

  color: #fff;
`;

export const Cam = styled.img`
  width: 100%;
  /* background: #000; */

  height: 300px;
`;
