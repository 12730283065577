import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/custom/Button';
import Modal from '../../../components/custom/Modal';
import api from '../../../services/api';
import CommonStructurePage from '../../CommonStructurePage';

import {
  Container,
  Wrapper,
  WrapperInput,
  Descriptions,
  Input,
} from './styles';

const PersistData: React.FC = () => {
  const [data, setData] = useState<string>();
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    api
      .get('/persistdata')
      .then((response) => {
        setData(response.data.persist_time);
      })
      .catch((error) => {
        error;
      });
  }, []);

  const handleRegister = () => {
    api
      .post('/persistdata', { persist_time: data })
      .then(() => {
        setSucessRegister(true);
      })
      .catch(() => {
        setErroRegister(true);
      });
  };

  return (
    <CommonStructurePage>
      <Container>
        <Wrapper>
          <WrapperInput>
            <Descriptions>Tempo de persistência:</Descriptions>
            <Input
              type="text"
              // placeholder={data}
              value={data}
              onChange={(e) => setData(e.currentTarget.value)}
            />
          </WrapperInput>
          <Button
            text="Enviar"
            color="#fff"
            background="#040E21"
            clickFunction={handleRegister}
          />
        </Wrapper>
      </Container>
      {sucessRegister ? (
        <Modal type="sucess" text="Dispositivo editado com sucesso!">
          <Button
            text="OK"
            color="#FFFFFF"
            background="#040E21"
            className="button-cancel"
            clickFunction={() => {
              setSucessRegister(false);
              history.push('/registered-devices');
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {erroRegister ? (
        <Modal type="error" text="Falha ao editar Dispositivo!">
          <Button
            text="OK"
            color="#FFFFFF"
            background="#1C3359"
            className="button-cancel"
            clickFunction={() => setErroRegister(false)}
          />
        </Modal>
      ) : (
        <></>
      )}
    </CommonStructurePage>
  );
};

export default PersistData;
