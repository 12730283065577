import React, { useState } from 'react';
import { RiSaveLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import CommonStructurePage from '../../../CommonStructurePage';

import {
  Container,
  Title,
  Descriptions,
  Input,
  FormData,
  WrapperInput,
  Wrapper,
  WrapperButtons,
  WrapperContainer,
} from './styles';

import Button from '../../../../components/custom/Button';
import Modal from '../../../../components/custom/Modal';

const RegisterPlace: React.FC = () => {
  const [camNumber, setCamNumber] = useState<string>();
  const [lat, setLat] = useState<string>();
  const [lng, setLng] = useState<string>();
  const [name, setName] = useState<string>();
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);

  const history = useHistory();

  const handleRegister = () => {
    const data = {
      cam_numbers: camNumber,
      lat,
      lng,
      name,
    };
    api
      .post('places/', data)
      .then(() => setSucessRegister(true))
      .catch(() => setErroRegister(true));
  };

  return (
    <CommonStructurePage>
      <WrapperContainer>
        <Container>
          <Title>Cadastrar um local</Title>
          <Descriptions>Preencha os campos a seguir:</Descriptions>
          <FormData>
            <Wrapper>
              <WrapperInput>
                <Descriptions>Nome:</Descriptions>
                <Input
                  type="text"
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </WrapperInput>
              <WrapperInput>
                <Descriptions>Número de câmeras:</Descriptions>
                <Input
                  type="text"
                  onChange={(e) => setCamNumber(e.currentTarget.value)}
                />
              </WrapperInput>
              <WrapperInput>
                <Descriptions>Latitude:</Descriptions>
                <Input
                  type="text"
                  onChange={(e) => setLat(e.currentTarget.value)}
                />
              </WrapperInput>
              <WrapperInput>
                <Descriptions>Longitude:</Descriptions>
                <Input
                  type="text"
                  onChange={(e) => setLng(e.currentTarget.value)}
                />
              </WrapperInput>
            </Wrapper>
            <WrapperButtons>
              <Button
                text="Cancelar"
                color="#040E21"
                background="#fff"
                clickFunction={() => history.push('/registered-places')}
              />
              <Button
                text="Salvar"
                color="#fff"
                Icon={RiSaveLine}
                background="#040E21"
                clickFunction={handleRegister}
              />
            </WrapperButtons>
          </FormData>
          {sucessRegister ? (
            <Modal type="sucess" text="Local cadastrado com sucesso!">
              <Button
                text="OK"
                color="#FFFFFF"
                background="#040E21"
                className="button-cancel"
                clickFunction={() => {
                  setSucessRegister(false);
                  history.push('/registered-places');
                }}
              />
            </Modal>
          ) : (
            <></>
          )}
          {erroRegister ? (
            <Modal type="error" text="Falha ao cadastrar local!">
              <Button
                text="OK"
                color="#FFFFFF"
                background="#1C3359"
                className="button-cancel"
                clickFunction={() => setErroRegister(false)}
              />
            </Modal>
          ) : (
            <></>
          )}
        </Container>
      </WrapperContainer>
    </CommonStructurePage>
  );
};

export default RegisterPlace;
