import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  padding: 18px 36px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border-radius: 4px;

  background: #09172F;

  color: var(--primary-color);
`;

export const DeviceList = styled.div`
  width: 100%;
  max-height: 90px;

  overflow-y: auto;

  gap: 5px;

  background-color: #152744a9;
`;

export const CardHeader = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const UserName = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #fff;
`;

export const Icons = styled.div`
  display: flex;
  gap: 8px;

  color: var(--icons-color);
`;

export const CardContent = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  gap: 5px;
`;

export const InfoSections = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  background-color: #152744;

  border-radius: 5px;
`;

export const TitleSection = styled.p`
  color: #fff;
`;

export const DevicesTitle = styled.p`
  color: #fff;
  align-self: flex-start;
`;

export const TextSection = styled.p`
  color: #fff;
  font-weight: 700;
`;

export const DevicesText = styled.p`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  
  background-color: #0c1b33;

  color: #fff;
  font-weight: 700;

  margin-bottom: 5px;
`;

export const DevicesSections = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;

  padding: 10px;

  background-color: #152744;

  border-radius: 5px;
`;
