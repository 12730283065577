import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';

import api from '../../../../services/api';

import { IPlaces } from '../RegisteredPlace';

import {
  Container,
  CardContent,
  CardHeader,
  InfoSections,
  Icons,
  TextSection,
  TitleSection,
  UserName,
  DevicesSections,
  DevicesText,
  DevicesTitle,
  DeviceList,
} from './styles';
import Modal from '../../../../components/custom/Modal';
import CustomButton from '../../../../components/custom/Button';

interface Props {
  place: IPlaces;
}

interface DevicesProps {
  id: number;
  name: string;
}

const PlaceCard: React.FC<Props> = ({ place }) => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [sucessModal, setSucessModal] = useState<boolean>(false);
  const [hideCard, setHideCard] = useState<string>('flex');
  const [devicesName, setDevicesName] = useState<DevicesProps[]>();

  const history = useHistory();

  useEffect(() => {
    setDevicesName(place.devices);
  }, []);

  function openWarningDelete() {
    setConfirmModal(true);
  }

  function deletePlace(id: number) {
    setConfirmModal(false);
    api
      .delete(`places/${id}/`)
      .then(() => {
        setHideCard('none');
        setSucessModal(true);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {confirmModal ? (
        <Modal text="Tem certeza que deseja excluir o local?" type="warning">
          <CustomButton
            text="Não excluir"
            clickFunction={() => setConfirmModal(false)}
            color="var(--white-text)"
            border="1px solid var(--primary-color)"
            background="#1C3359"
          />
          <CustomButton
            text="Excluir"
            clickFunction={() => deletePlace(place.id)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      {sucessModal ? (
        <Modal text="Local excluído com sucesso!" type="sucess">
          <CustomButton
            text="OK"
            clickFunction={() => setSucessModal(false)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      <Container style={{ display: hideCard }}>
        <CardHeader>
          <UserName>{place.name}</UserName>
          <Icons>
            <RiEdit2Line
              size={22}
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => {
                history.push(`/edit-place/${place.id}`);
              }}
            />
            <RiDeleteBin2Line
              size={22}
              data-testid="deleteuser"
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => openWarningDelete()}
            />
          </Icons>
        </CardHeader>
        <CardContent>
          <InfoSections>
            <TitleSection>Latitude:</TitleSection>

            <TextSection>{place.lat}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Longitude:</TitleSection>

            <TextSection>{place.lng}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Câmeras:</TitleSection>

            <TextSection>{place.cam_numbers}</TextSection>
          </InfoSections>
          <DevicesSections>
            <DevicesTitle>Dispositivos:</DevicesTitle>
            <DeviceList>
              {devicesName?.map((item) => (
                <DevicesText
                  onClick={() => {
                    history.push(`/devices/${place.id}`);
                  }}
                >
                  {item.name}
                </DevicesText>
              ))}
            </DeviceList>
          </DevicesSections>
        </CardContent>
      </Container>
    </>
  );
};

export default PlaceCard;
