import styled from 'styled-components';

interface Props {
  expand: boolean;
  subExpand: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;

  display: flex;
  align-items: center;

  a,
  .complex-menu-item {
    width: 100%;
    min-height: 50px;
    max-height: ${(props) => (props.subExpand ? 'auto' : '50px')};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => (props.expand ? 'flex-start' : 'center')};

    color: var(--white-text);

    overflow: hidden;

    transition: max-height 0.6s;

    .icon-title-div {
      width: 100%;
      padding: ${(props) => (props.expand ? '12px 0 14px 12px' : '12x 0')};

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .sub-items {
      width: 100%;
    }

    .sub-items a {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      margin-left: 50px;
    }

    h3 {
      display: ${(props) => (props.expand ? 'block' : 'none')};
      font-size: 16px;
    }
  }
`;
