import React, { useState, useEffect } from 'react';
import { RiSaveLine, RiDeleteBin2Line, RiEdit2Line } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import CommonStructurePage from '../../../CommonStructurePage';

import {
  Container,
  Title,
  Descriptions,
  Input,
  FormData,
  WrapperInput,
  Wrapper,
  WrapperButtons,
  SubTitle,
  WrapperContainer,
  DevicesSections,
  DevicesTitle,
  DevicesText,
  WrapperDeviceButton,
  DeviceList,
} from './styles';

import Button from '../../../../components/custom/Button';
import Modal from '../../../../components/custom/Modal';

interface DevicesProps {
  id: number;
  name: string;
}

const EditPlace: React.FC = () => {
  const [lat, setLat] = useState<string>();
  const [name, setName] = useState<string>();
  const [lng, setLng] = useState<string>();
  const [cam_numbers, setCam_numbers] = useState<string>();
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);
  const [devices, setDevices] = useState<DevicesProps[]>();
  const [sucessModal, setSucessModal] = useState<boolean>(false);
  const [hideCard, setHideCard] = useState<string>('flex');
  const [deviceID, setDeviceID] = useState<string | undefined>();

  const history = useHistory();

  useEffect(() => {
    const id = document.URL;
    const idAtt = id.split('/')[4];
    setDeviceID(idAtt);

    api.get(`places/${idAtt}/`).then((response) => {
      setDevices(response.data.devices);
      setName(response.data.name);
      setLat(response.data.lat);
      setLng(response.data.lng);
      setCam_numbers(response.data.cam_numbers);
    });
  }, []);

  const handleRegister = () => {
    const id = document.URL;
    const idUser = id.split('/')[4];

    const data = {
      lat,
      name,
      lng,
      cam_numbers,
    };
    api
      .put(`places/${idUser}/`, data)
      .then(() => setSucessRegister(true))
      .catch(() => setErroRegister(true));
  };

  function openWarningDelete(id: number) {
    setSucessModal(true);
    api.delete(`devices/${id}/`);
  }

  return (
    <CommonStructurePage>
      {sucessModal ? (
        <Modal text="Dispositivo excluído com sucesso!" type="sucess">
          <Button
            text="OK"
            clickFunction={() => setSucessModal(false)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      <WrapperContainer>
        <Container>
          <Title>Editar local</Title>
          <SubTitle>Preencha os campos a seguir:</SubTitle>
          <FormData>
            <Wrapper>
              <WrapperInput>
                <Descriptions>Nome</Descriptions>
                <Input
                  type="text"
                  placeholder={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </WrapperInput>
              <WrapperInput>
                <Descriptions>Latitude</Descriptions>
                <Input
                  type="text"
                  placeholder={lat}
                  onChange={(e) => setLat(e.currentTarget.value)}
                />
              </WrapperInput>
              <WrapperInput>
                <Descriptions>Longitude</Descriptions>
                <Input
                  type="text"
                  placeholder={lng}
                  onChange={(e) => setLng(e.currentTarget.value)}
                />
              </WrapperInput>
              <DevicesSections>
                <DevicesTitle>Dispositivos:</DevicesTitle>
                <DeviceList>
                  {devices &&
                    devices?.map((item) => (
                      <WrapperDeviceButton>
                        <DevicesText
                          onClick={() => {
                            history.push(`/devices/${item.id}`);
                          }}
                        >
                          {item.name}
                        </DevicesText>
                        <RiEdit2Line
                          size={22}
                          style={{ cursor: 'pointer', color: '#fff' }}
                          onClick={() => {
                            history.push(`/edit-device/${item.id}`);
                          }}
                        />
                        <RiDeleteBin2Line
                          size={22}
                          data-testid="deleteuser"
                          style={{
                            cursor: 'pointer',
                            color: '#fff',
                            marginRight: '10px',
                          }}
                          onClick={() => openWarningDelete(item.id)}
                        />
                      </WrapperDeviceButton>
                    ))}
                </DeviceList>
              </DevicesSections>
            </Wrapper>
            <WrapperButtons>
              <Button
                text="Cancelar"
                color="#041a44"
                background="#fff"
                clickFunction={() => history.push('/registered-places')}
              />
              <Button
                text="Salvar"
                data-testid="handleregister"
                color="#fff"
                Icon={RiSaveLine}
                background="#040E21"
                clickFunction={handleRegister}
              />
            </WrapperButtons>
          </FormData>
          {sucessRegister ? (
            <Modal type="sucess" text="Local alterado com sucesso!">
              <Button
                text="OK"
                color="#FFFFFF"
                background="#040E21"
                className="button-cancel"
                clickFunction={() => setSucessRegister(false)}
              />
            </Modal>
          ) : (
            <></>
          )}
          {erroRegister ? (
            <Modal type="error" text="Falha ao alterar o Local!">
              <Button
                text="OK"
                color="#FFFFFF"
                background="#040E21"
                className="button-cancel"
                clickFunction={() => setErroRegister(false)}
              />
            </Modal>
          ) : (
            <></>
          )}
        </Container>
      </WrapperContainer>
    </CommonStructurePage>
  );
};

export default EditPlace;
