import styled from 'styled-components';

interface Props {
  expand: boolean;
}

export const Container = styled.div<Props>`
  width: ${(props) => (props.expand ? '100vw' : '0')};
  height: calc(100vh - 60px);

  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: var(--sidebar);

  overflow-y: auto;

  z-index: 10;

  .icons {
    margin-right: 10px;
  }

  @media (min-width: 1028px) {
    width: ${(props) => (props.expand ? '400px' : '0')};

    position: relative;
  }

  transition: all 0.3s;
`;

export const ButtonNavigate = styled.button`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  background: none;
  color: white;
  font-size: 16px;
`;
