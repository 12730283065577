const formatToInputDate = (unFormatedDate: string) => {
  const newDate = new Date(unFormatedDate);
  return `${
    newDate.getDate() > 9
      ? newDate.getDate()
      : `0${  newDate.getDate()}`
  }/${
    newDate.getMonth() >= 9
      ? newDate.getMonth() + 1
      : `0${  newDate.getMonth() + 1}`
  }/${newDate.getFullYear()} - ${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`
};
export default formatToInputDate;