import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { RiAddCircleLine, RiSearchLine } from 'react-icons/ri';

import Button from '../../../components/custom/Button';
import CommonStructurePage from '../../CommonStructurePage';
import api from '../../../services/api';

import {
  Container,
  Title,
  SelectContainer,
  TitleContainer,
  Cards,
  ButtonContainer,
} from './styles';
import UserCard from '../UserCard';


export interface IUsers {
  id: number;
  email: string;
  name: string;
  phone: number;
}

const User: React.FC = () => {
  const [stateData, setStateData] = useState<any>();
  // const [profileFilterState, setProfileFilterState] = useState<string>('0');
  // const [statusFilterState, setStatusFilterState] = useState<string>('0');
  const [findUsers, setFindUsers] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    api.get('/authenticate/user/list/').then((response) => {
      setStateData(response.data);
    });
  }, []);

  const handleFilterUsers = () => {
    let tempData: IUsers[] = stateData;


    if (findUsers !== '') {
      tempData = tempData.filter(({ name }) => name.includes(findUsers));
    } else {
      api.get('/authenticate/user/list/').then((response) => {
        setStateData(response.data);
      });
    }

    setStateData(tempData);
  };

  return (
    <CommonStructurePage>
      <Container>
        <TitleContainer>
          <Title>Usuários cadastrados</Title>
        </TitleContainer>
        <TitleContainer>
          <SelectContainer>
            <TextField
              id="standard-basic"
              value={findUsers}
              onChange={(e) => setFindUsers(e.currentTarget.value)}
              style={{ width: '300px' }}
              placeholder="Buscar usuário"
              className="MuiInput-root"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearchLine size={18} color="#808495" />
                  </InputAdornment>
                ),
              }}
            />
          </SelectContainer>
          <ButtonContainer>
            <Button
                text="Filtrar"
                color="#fff"
                background="#1C3359"
                clickFunction={handleFilterUsers}
              />
              <Button
              text="Cadastrar novo"
              color="#fff"
              Icon={RiAddCircleLine}
              background="#1C3359"
              clickFunction={() => history.push('/register-users')}
            />
          </ButtonContainer>
        </TitleContainer>

        <Cards>
          {stateData?.map((user: IUsers) => (
            <UserCard user={user} />
          ))}
        </Cards>
      </Container>
    </CommonStructurePage>
  );
};

export default User;
