import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 60px;

  padding: 16px 16px 16px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;

  background: var(--header-background);

  box-shadow: 0px 1px 6px #0008;

  .bell-icon {
    fill: var(--white-text);
  }

  .user-icon {
    fill: var(--white-text);

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LogoPrimer = styled.img`
  width: 35px;
  height: 29px;
  transform: translate(-40%);

  @media (min-width: 980px) {
    width: 100%;
    height: auto;
    transform: translate(50%);
    display: inherit;
  }
`;

export const SidebarLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--white-text);

  background: none;

  gap: 12px;
`;

export const Main = styled.div`
  width: auto;
  height: 60px;

  padding: 16px 16px 16px 24px;
  gap: 18px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderUser = styled.a`
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 12px;

  color: var(--white-text);

  cursor: pointer;
`;

export const HeaderUserName = styled.p`
  color: var(--white-text);

  @media (min-width: 980px) {
    font-size: 16px;
  }
`;

export const LogoutButton = styled.button`
  width: 80px;
  height: 30px;
  font-size: 16px;
  color: var(--white-text);
  background: var(--button-logout);

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
`;

export const NotificationContainer = styled.div`
  display: flex;
  align-items: center;

  color: var(--white-text);

  cursor: pointer;
`;

export const NotificationMarker = styled.p`
  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  font-size: 12px;

  margin: -10px 0 0 -8px;

  background: red;
`;

export const NotificationResults = styled.div`
  right: 146px;
  top: 44px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const NotificationTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--notification-box-background);
  margin-right: 19px;
`;

export const NotificationBox = styled.div`
  width: 340px;
  min-height: 52px;
  max-height: 300px;

  overflow-y: auto;

  border-radius: 8px;

  background: var(--notification-box-background);
`;

export const NotificationBoxHeader = styled.div`
  width: 100%;
  height: 52px;

  padding: 12px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 12px;
    font-weight: 600;

    color: var(--notification-primary-text-color);
  }

  button {
    color: var(--notification-link-text-color);
  }
`;

export const NotificationLine = styled.div`
  width: 100%;
  height: 52px;

  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid #3333;
`;

export const NotificationText = styled.p`
  width: 244px;

  text-align: left;

  color: var(--notification-primary-text-color);
`;

export const ProfileExpand = styled.div`
  position: absolute;
  right: 0px;
  top: 60px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  z-index: 9999;
`;

export const ProfileTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--notification-box-background);
  margin-right: 6px;
`;

export const ProfileBox = styled.div`
  width: 140px;
  min-height: 52px;
  max-height: 300px;

  display: flex;
  flex-direction: column;

  overflow-y: auto;

  border-radius: 8px;

  background: var(--notification-box-background);
`;

export const ProfileBoxItem = styled.button`
  width: 100%;
  height: 52px;

  padding: 12px 16px;

  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 12px;
  font-weight: 600;

  color: var(--notification-primary-text-color);
  background: var(--white-text);
`;

export const ProfileBoxItemLink = styled.a`
  width: 100%;
  height: 52px;

  padding: 12px 16px;

  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 12px;
  font-weight: 600;

  color: var(--notification-primary-text-color);
  background: var(--white-text);
`;
