import styled from 'styled-components';

interface Props {
  expand: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;

  display: flex;
  align-items: center;

  a {
    width: 100%;
    padding: ${(props) => (props.expand ? '12px 6px 14px 12px' : '12px 0')};

    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.expand ? 'flex-start' : 'center')};
    gap: 10px;

    h3 {
      display: ${(props) => (props.expand ? 'block' : 'none')};
      font-size: 16px;
    }
  }
`;
