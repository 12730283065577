import React from 'react';
import { Cam, Container } from './styles';
import CommonStructurePage from '../CommonStructurePage/index';

interface SpecifyCamProps {
  match: {
    params: {
      id: string;
    };
  };
}

const SpecifyCam: React.FC<SpecifyCamProps> = ({ match }) => {
  return (
    <>
      <CommonStructurePage>
        <Container>
          <Cam
            src={`${window.location.host.includes('localhost') ? 'http://localhost:8000' : `https://${window.location.host}`}/api/video/${match.params.id}/`}
            alt={`Camera ${match.params.id}`}
          />
        </Container>
      </CommonStructurePage>
    </>
  );
};

export default SpecifyCam;
