import React, { useState, useEffect } from 'react';
import { RiSaveLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import CommonStructurePage from '../../../CommonStructurePage';

import {
  Container,
  Title,
  Descriptions,
  Input,
  FormData,
  WrapperInput,
  Wrapper,
  WrapperButtons,
  WrapperContainer,
  Select,
  Option,
} from './styles';

import Button from '../../../../components/custom/Button';
import Modal from '../../../../components/custom/Modal';

const EditDevices: React.FC = () => {
  const [place, setPlace] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [placeId, setPlaceId] = useState<number>();
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);
  const [dataSelect, setDataSelect] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    api.get('places/').then((response) => {
      setDataSelect(response.data);
    });
  }, []);

  useEffect(() => {
    const id = document.URL;
    const idAtt = id.split('/')[4];

    api.get(`devices/${idAtt}/`).then((response) => {
      setName(response.data.device);
      setPlaceId(response.data.place_id);
      setPlace(response.data.place);
    });
  }, []);

  const handleRegister = () => {
    const id = document.URL;
    const idUser = id.split('/')[4];

    const data = {
      name,
      place_id: placeId,
    };
    api
      .put(`devices/${idUser}/`, data)
      .then(() => setSucessRegister(true))
      .catch(() => setErroRegister(true));
  };

  return (
    <CommonStructurePage>
      <WrapperContainer>
        <Container>
          <Title>Editar um Dispositivo</Title>
          <Descriptions>Preencha os campos a seguir:</Descriptions>
          <FormData>
            <Wrapper>
              <WrapperInput>
                <Descriptions>Nome:</Descriptions>
                <Input
                  type="text"
                  placeholder={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </WrapperInput>
              <WrapperInput>
                <Descriptions>Place</Descriptions>
                <Select
                  id="select-state"
                  onChange={(e: any) => setPlace(e.target.value)}
                >
                  <Option value="0" selected>
                    {place || 'Escolha uma opção'}
                  </Option>
                  {dataSelect &&
                    dataSelect.map((item: any) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                </Select>
              </WrapperInput>
            </Wrapper>
            <WrapperButtons>
              <Button
                text="Cancelar"
                color="#040E21"
                background="#fff"
                clickFunction={() => history.push('/registered-devices')}
              />
              <Button
                text="Salvar"
                color="#fff"
                Icon={RiSaveLine}
                background="#040E21"
                clickFunction={handleRegister}
              />
            </WrapperButtons>
          </FormData>
          {sucessRegister ? (
            <Modal type="sucess" text="Dispositivo editado com sucesso!">
              <Button
                text="OK"
                color="#FFFFFF"
                background="#040E21"
                className="button-cancel"
                clickFunction={() => {
                  setSucessRegister(false);
                  history.push('/registered-devices');
                }}
              />
            </Modal>
          ) : (
            <></>
          )}
          {erroRegister ? (
            <Modal type="error" text="Falha ao editar Dispositivo!">
              <Button
                text="OK"
                color="#FFFFFF"
                background="#1C3359"
                className="button-cancel"
                clickFunction={() => setErroRegister(false)}
              />
            </Modal>
          ) : (
            <></>
          )}
        </Container>
      </WrapperContainer>
    </CommonStructurePage>
  );
};

export default EditDevices;
