import styled from 'styled-components';

export const WrapperContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;


  @media (min-width: 980px) {
    width: 50vw;
  }
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: #152744;

  padding: 40px;

  gap: 20px;

  input {
    -webkit-appearance: none;
    box-shadow: none !important;
  }
  :-webkit-autofill {
    color: #fff !important;
  }

  .password {
    position: relative;
    bottom: 23px;
    left: 223px;
  }
`;

export const Title = styled.h1`
  font-size: 30px;

  align-self: flex-start;

  color: #fff;
`;

export const SubTitle = styled.h5`
  font-size: 15px;
  align-self: flex-start;
  margin-left: 20px;
  color: #fff;

`;

export const Descriptions = styled.p`
  font-size: 15px;
  color: #fff;

`;

export const WrapperButtons = styled.div`
  width: 98%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  gap: 20px;
`;

export const FormData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const WrapperInput = styled.div`
  width: 98%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Input = styled.input`
  width: 250px;
  height: 30px;
  padding: 0 10px;
  margin-top: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #bbbbbc;
  border-radius: 3px;
  opacity: 1;
  border: none;
  margin-bottom: 10px;
`;
