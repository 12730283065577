import React, { useState, useEffect } from 'react';
import { RiSaveLine, RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import CommonStructurePage from '../../CommonStructurePage';

import {
  Container,
  Title,
  Descriptions,
  Input,
  FormData,
  WrapperInput,
  Wrapper,
  WrapperButtons,
  WrapperContainer,
} from './styles';

import Button from '../../../components/custom/Button';
import Modal from '../../../components/custom/Modal';

const RegisterUsers: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [name, setName] = useState<string>();
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);
  const [hiddenPassword, setHiddenPassword] = useState(false);
  const [showMe, setShowMe] = useState('password');

  const history = useHistory();

  useEffect(() => {
    if (hiddenPassword === true) {
      setShowMe('text');
    } else {
      setShowMe('password');
    }
  }, [hiddenPassword]);

  const handleRegister = () => {
    const data = {
      email,
      password,
      name,
    };
    api
      .post('authenticate/user/create/', data)
      .then(() => setSucessRegister(true))
      .catch(() => setErroRegister(true));
  };

  return (
    <CommonStructurePage>
      <WrapperContainer>
      <Container>
        <Title>Cadastrar usuário</Title>
        <Descriptions>Preencha os campos a seguir:</Descriptions>
        <FormData>
          <Wrapper>
            <WrapperInput>
              <Descriptions>Nome completo</Descriptions>
              <Input
                type="text"
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>E-mail</Descriptions>
              <Input
                type="text"
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Senha</Descriptions>
              <Input
                type={showMe}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
              {hiddenPassword ? (
                <RiEyeLine
                  onClick={() => setHiddenPassword(false)}
                  className="password"
                />
              ) : (
                <RiEyeOffLine
                  onClick={() => setHiddenPassword(true)}
                  className="password"
                />
              )}
            </WrapperInput>
          </Wrapper>
          <WrapperButtons>
            <Button
              text="Cancelar"
              color="#040E21"
              background="#fff"
              clickFunction={() => history.push('/registered-users')}
            />
            <Button
              text="Salvar"
              color="#fff"
              Icon={RiSaveLine}
              background="#040E21"
              clickFunction={handleRegister}
            />
          </WrapperButtons>
        </FormData>
        {sucessRegister ? (
          <Modal type="sucess" text="Usuário cadastrado com sucesso!">
            <Button
              text="OK"
              color="#FFFFFF"
              background="#040E21"
              className="button-cancel"
              clickFunction={() => {
                setSucessRegister(false);
                history.push('/registered-users');
              }}
            />
          </Modal>
        ) : (
          <></>
        )}
        {erroRegister ? (
          <Modal type="error" text="Falha ao cadastrar usuário!">
            <Button
              text="OK"
              color="#FFFFFF"
              background="#1C3359"
              className="button-cancel"
              clickFunction={() => setErroRegister(false)}
            />
          </Modal>
        ) : (
          <></>
        )}
      </Container>
      </WrapperContainer>
    </CommonStructurePage>
  );
};

export default RegisterUsers;
