import React, { useState } from 'react';
import EventBox from '../EventBox';

import {
  AlertBox,
  AlertImage,
  AlertTexts,
  AlertAdress,
  AlertCam,
  AlertWeapon,
} from '../custom/Alert/styles';

const AlertBoxItems: React.FC<{ event: any }> = ({ event }) => {
  const [callEvent, setCallEvent] = useState<boolean>(false);
  const formatDate = (unformatedDate: string) => {
    const date: any = new Date(unformatedDate);

    return `${date.getHours()}:${date.getMinutes()} em ${
      date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`
    }-${
      date.getMonth >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    }-${date.getFullYear()}`;
  };

  const handleCallEvent = () => {
    setCallEvent(!callEvent);
  };

  return (
    <>
      <EventBox
        event={event}
        visible={callEvent}
        setVisible={handleCallEvent}
      />
      <AlertBox key={event.id} onClick={handleCallEvent}>
        <AlertImage>
          <img src={event.picture} alt="test" className="alert-image" />
        </AlertImage>
        <AlertTexts>
          <AlertCam>{`Câmera ${event.id}`}</AlertCam>
          <AlertWeapon>{event.type_weapon} - detectada</AlertWeapon>
          <AlertAdress>Detectada às: {formatDate(event.datetime)}</AlertAdress>
        </AlertTexts>
      </AlertBox>
    </>
  );
};

export default AlertBoxItems;
