import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';

import api from '../../../../services/api';

import { IDevices } from '../RegisteredDevices';

import {
  Container,
  CardContent,
  CardHeader,
  InfoSections,
  Icons,
  TextSection,
  TitleSection,
  UserName,
  DevicesSections,
  DevicesText,
  DevicesTitle,
  DeviceList,
} from './styles';
import Modal from '../../../../components/custom/Modal';
import CustomButton from '../../../../components/custom/Button';

interface Props {
  devices: IDevices;
}

const DevicesCard: React.FC<Props> = ({ devices }) => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [sucessModal, setSucessModal] = useState<boolean>(false);
  const [hideCard, setHideCard] = useState<string>('flex');
  const [devicesName, setDevicesName] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    setDevicesName(devices.cameras);
  }, []);

  function openWarningDelete() {
    setConfirmModal(true);
  }

  function deleteDevice(id: number) {
    setConfirmModal(false);
    api
      .delete(`devices/${id}/`)
      .then(() => {
        setHideCard('none');
        setSucessModal(true);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {confirmModal ? (
        <Modal text="Tem certeza que deseja excluir o local?" type="warning">
          <CustomButton
            text="Não excluir"
            clickFunction={() => setConfirmModal(false)}
            color="var(--white-text)"
            border="1px solid var(--primary-color)"
            background="#1C3359"
          />
          <CustomButton
            text="Excluir"
            clickFunction={() => deleteDevice(devices.id)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      {sucessModal ? (
        <Modal text="Local excluído com sucesso!" type="sucess">
          <CustomButton
            text="OK"
            clickFunction={() => setSucessModal(false)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      <Container style={{ display: hideCard }}>
        <CardHeader>
          <UserName>{devices.device}</UserName>
          <Icons>
            <RiEdit2Line
              size={22}
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => {
                history.push(`/edit-device/${devices.id}`);
              }}
            />
            <RiDeleteBin2Line
              size={22}
              data-testid="deleteuser"
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => openWarningDelete()}
            />
          </Icons>
        </CardHeader>
        <CardContent>
          <InfoSections>
            <TitleSection>Latitude:</TitleSection>

            <TextSection>{devices.lat}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Longitude:</TitleSection>

            <TextSection>{devices.lng}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Câmeras:</TitleSection>
          </InfoSections>
          <DevicesSections>
            <DevicesTitle>Câmeras:</DevicesTitle>
            <DeviceList>
              {devicesName?.map((item: any) => (
                <DevicesText
                  onClick={() => {
                    history.push(`/devices/${devices.id}`);
                  }}
                >
                  {item.camera}
                </DevicesText>
              ))}
            </DeviceList>
          </DevicesSections>
        </CardContent>
      </Container>
    </>
  );
};

export default DevicesCard;
