import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 10px;

  @media (min-width: 980px) {
    width: 20%
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: auto;

  padding: 24px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  border-radius: 5px;

  background-color: #152744;
`;