import React, { useState } from 'react';
import { IconType } from 'react-icons';
import { RiArrowDownSLine } from 'react-icons/ri';
import { NavLink, useLocation } from 'react-router-dom';

import { Container } from './styles';

interface Props {
  title: string;
  urlPath: string;
  Icon: IconType;
  expand: boolean;
}

const ComplexMenuItem: React.FC<Props> = ({
  title,
  urlPath,
  Icon,
  expand,
  children,
}) => {
  const [isSubExpand, setIsSubExpand] = useState(false);

  const location =
    useLocation().pathname.split('/')[1] === urlPath.split('/')[1]
      ? 'active-route'
      : '';

  return (
    <Container expand={expand} subExpand={isSubExpand} onClick={() => setIsSubExpand(!isSubExpand)} style={{cursor: 'pointer'}}>
      {expand ? (
        <div className="complex-menu-item">
          <div className={`icon-title-div ${location}`}>
            <div>
              <Icon size={22} />
              <h3>{title}</h3>
            </div>
            <RiArrowDownSLine
              className="arrow-down"
              size={24}
              style={{ cursor: 'pointer', paddingRight: '6px' }}
              onClick={() => setIsSubExpand(!isSubExpand)}
            />
          </div>
          <div className="sub-items">{children}</div>
        </div>
      ) : (
        <NavLink to={urlPath} className={location}>
          <Icon size={26} />
          <h3>{title}</h3>
        </NavLink>
      )}
    </Container>
  );
};

export default ComplexMenuItem;
