import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 20px;

  @media (min-width: 980px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Cam = styled.img`
  width: 100%;
  height: calc(50vh - 50px);

  @media (min-width: 980px) {
    width: 50%;
    height: calc(80vh - 50px);
  }
`;
