import styled from 'styled-components';
import background from '../../assets/background.png';

export const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 60px);

  display: flex;

  background: var(--background);
  background-image: url(${background});
`;

export const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  padding: 20px;
  overflow: auto;
`;
