import React, { useState, createContext, useCallback, useContext } from 'react';
import api from '../services/api';

// import { Container } from './styles';

interface AuthState {
  token: any;
  user: any;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  token: string;
  user: any;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  stateEvents: [];
  getEvents(): void;
}

interface EventState {
  id: number;
  type_weapon: string;
  mac_address: string;
  lat: number;
  lng: number;
  accuracy: number;
  ip_address: string;
  place: string;
  picture: string;
}

const AuthContext = createContext({});

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@GUN:user');
    const token = localStorage.getItem('@GUN:token');

    if (token && user) {
      return { token, user };
    }

    return {token: null, user: null};
  });

  const [stateEvents, setStateEvents] = useState<EventState[]>();

  const getEvents = async () => {
    const response = await api.get('events/');

    return setStateEvents(response.data);
  };

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('authenticate/token/obtain/', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@GUN:token', token);
    localStorage.setItem('@GUN:user', JSON.stringify(user));
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@GUN:token');
    localStorage.removeItem('@GUN:user');

    setData({token: null, user: null});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        stateEvents,
        getEvents,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context: any = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth precisa ser utilizado junto ao AuthProvider');
  }

  return context;
}
