import React, { useEffect, useState } from 'react';
import { RiLogoutBoxRLine, RiSettings3Fill } from 'react-icons/ri';
import { FaPlusSquare, FaBell, FaUserCircle } from 'react-icons/fa';
import { AiOutlineMenu, AiOutlineMenuUnfold } from 'react-icons/ai';
import { NavLink, useHistory } from 'react-router-dom';

import {
  Container,
  HeaderUser,
  HeaderUserName,
  NotificationContainer,
  NotificationMarker,
  NotificationResults,
  NotificationTriangle,
  NotificationBox,
  NotificationBoxHeader,
  NotificationLine,
  Main,
  NotificationText,
  ProfileBoxItemLink,
  ProfileExpand,
  ProfileTriangle,
  LogoutButton,
  ProfileBox,
  SidebarLeft,
  ProfileBoxItem,
  LogoPrimer,
} from './styles';

import PrimerAnalytics from '../../../assets/logo_primer03.png';
import PrimerMobile from '../../../assets/logo_mobile.png';
// import Sidebar from '../Sidebar/index';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/AuthContext';

interface INotifications {
  type: string;
  value: number;
  ug: number;
}

interface Sidebar {
  setSidebarExpand: (state: boolean) => void;
  setAlertExpand: (state: boolean) => void;
}

const Header: React.FC<Sidebar> = ({ setSidebarExpand, setAlertExpand }) => {
  const [notifications, setNotifications] = useState<INotifications[]>();
  const [notificationsExpand, setNotificationsExpand] = useState(false);
  const [profileExpand, setProfileExpand] = useState(false);
  const [menuSideExpand, setMenuSideExpand] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [newStateEvents, setNewStateEvents] = useState<any>();

  let user: any = localStorage.getItem('@GUN:user');

  const { signOut, stateEvents } = useAuth();
  const history = useHistory();

  if (user) {
    user = JSON.parse(user);
  }

  function handleLogout() {
    history.push('/');
    return signOut();
  }

  const windowWidth = window.innerWidth;
  const MIN_WINDOW_WIDTH = 980;

  useEffect(() => {
    setNewStateEvents(stateEvents);
  }, [stateEvents]);

  useEffect(() => {
    if (windowWidth <= MIN_WINDOW_WIDTH) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);

  return (
    <Container>
      <SidebarLeft
        onClick={() => {
          setSidebarExpand(true);
          setMenuSideExpand(!menuSideExpand);
        }}
      >
        {!menuSideExpand ? (
          <AiOutlineMenu size={25} />
        ) : (
          <AiOutlineMenuUnfold size={25} />
        )}
      </SidebarLeft>
      <NavLink to="/home">
        {isMobile ? (
          <LogoPrimer src={PrimerMobile} />
        ) : (
          <LogoPrimer src={PrimerAnalytics} />
        )}
      </NavLink>

      <Main>
        <FaUserCircle
          size={25}
          className="user-icon"
          onClick={() => history.push('/registered-users')}
        />
        {user && (
          <>
            {isMobile ? (
              <HeaderUserName>{user.name}</HeaderUserName>
            ) : (
              <HeaderUserName>{user.name}</HeaderUserName>
            )}
          </>
        )}

        {/* <LogoutButton onClick={handleLogout}>Logout</LogoutButton> */}
        <NotificationContainer
          data-testid="notification-icon"
          onClick={() => {
            setAlertExpand(true);
          }}
        >
          <FaBell className="bell-icon" size={20} />
          {newStateEvents && newStateEvents.length > 0 ? (
            <NotificationMarker>{newStateEvents.length}</NotificationMarker>
          ) : (
            <></>
          )}
        </NotificationContainer>
        {newStateEvents && notificationsExpand && newStateEvents.length > 0 ? (
          <NotificationResults data-testid="notification-results">
            <NotificationTriangle />
            <NotificationBox>
              <NotificationBoxHeader>
                <p>testing</p>
                <button type="button">click</button>
              </NotificationBoxHeader>
            </NotificationBox>
          </NotificationResults>
        ) : (
          <></>
        )}
        <HeaderUser
          data-testid="profile-icon"
          onClick={() => setProfileExpand(!profileExpand)}
        >
          <RiSettings3Fill size={20} />
        </HeaderUser>
        {profileExpand ? (
          <ProfileExpand>
            <ProfileTriangle />
            <ProfileBox>
              <ProfileBoxItemLink href="/register-menu">
                <FaPlusSquare size={18} />
                Registrar
              </ProfileBoxItemLink>
              <ProfileBoxItem onClick={handleLogout}>
                <RiLogoutBoxRLine size={18} />
                Sair
              </ProfileBoxItem>
            </ProfileBox>
          </ProfileExpand>
        ) : (
          <></>
        )}
      </Main>
    </Container>
  );
};

export default Header;
