import styled from 'styled-components';

export const Container = styled.div<any>`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: #0008;

  z-index: 999;
`;

export const Closed = styled.button`
  width: 100%;
  
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 42px;

  cursor: pointer;

  color: #fff;

  background: none;

  @media (min-width: 980px) {
    width: 40vw;
    min-width: 900px;
    /* height: 90vh; */
    margin-right: 0;
    margin-left: 42px;


    border-radius: 5px;
  }
`;

export const Main = styled.div`
  width: 100%;
  /* height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: var(--button-logout);
  opacity: 1;

  gap: 5px;

  @media (min-width: 980px) {
    width: 40vw;
    min-width: 900px;
    /* height: 90vh; */

    border-radius: 5px;
  }
`;

export const TextModal = styled.p`
  font-size: 19px;
  font-weight: 600;
  color: var(--white-text);
  text-align: center;
`;

export const Header = styled.div`
  width: 100%;
  height: 8vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: var(--header-background);
`;

export const Footer = styled.div`
  width: 80%;
  height: 8vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 5px;

  margin-bottom: 5px;
`;

export const Informations = styled.div`
  width: 100%;
  height: 4vh;

  display: flex;
  align-items: center;
  justify-content: space-around;

  background: var(--background);
  color: var(--white-text);

  border-radius: 5px;
`;

export const Title = styled.h1`
  font-size: 25px;
  font-weight: 600;
  color: var(--white-text);
  text-align: center;
`;

export const Weapon = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: var(--white-text);
`;

export const NumberEvent = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: var(--white-text);
`;

export const Accuracy = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: var(--white-text);
`;

export const SubTitle = styled.h5`
  font-size: 15px;
  font-weight: 600;
  color: #ded5d5;
  text-align: center;
`;

export const Screen = styled.img`
  width: 80%;
  max-height: 80vh;
`;

export const SubTextModal = styled.p`
  font-size: 14px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: end;
`;
