import React, { ChangeEvent, useState } from 'react';
import { RiUser3Fill } from 'react-icons/ri';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../../hooks/AuthContext';

import {
  Container,
  LeftMain,
  PrimerLogo,
  WelcomeText,
  SubWelcomeText,
  RightMain,
  CirclePeople,
  InputsContainer,
  InputDiv,
  Input,
  LoginButton,
  RememberContainer,
  RememberText,
  LoginOptions,
} from './styles';
import logo from '../../../assets/logo_primer01.png';

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const [cookies, setCookie] = useCookies(['central-system']);

  // @ts-ignore
  const [email, setEmail] = useState<string>(cookies.email || '');
  // @ts-ignore
  const [password, setPassword] = useState<string>(cookies.password || '');
  const [checkValue, setCheckValue] = useState<boolean>(true);

  const history = useHistory();

  // useEffect(() => {
  //   if (cookies.token !== 'invalid_token') {
  //     history.push('/');
  //   }
  // }, [cookies.token]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleCheckbox = () => {
    return setCheckValue(!checkValue);
  };

  const handleSendLogin = async () => {
    if (checkValue) {
      // @ts-ignore
      setCookie('email', email);
      // @ts-ignore
      setCookie('password', password);
    } else {
      // @ts-ignore
      setCookie('email', '');
      // @ts-ignore
      setCookie('password', '');
    }

    signIn({ email, password })
      .then(() => history.push('/home'))
      .catch(() => alert('Credenciais inválidas'));
  };

  return (
    <Container>
      <LeftMain>
        <PrimerLogo src={logo} alt="logo" />
        <WelcomeText>Bem-vindo</WelcomeText>
        <SubWelcomeText>Realize seu Login!</SubWelcomeText>
      </LeftMain>
      <RightMain>
        <CirclePeople>
          <RiUser3Fill size={116} />
        </CirclePeople>
        <InputsContainer>
          <InputDiv>
            <Input
              type="email"
              placeholder="E-MAIL"
              onChange={handleChangeEmail}
              defaultValue={email}
            />
          </InputDiv>
          <InputDiv>
            <Input
              type="password"
              placeholder="SENHA"
              onChange={handleChangePassword}
              defaultValue={password}
            />
          </InputDiv>
          <LoginOptions>
            <RememberContainer>
              <Checkbox
                onChange={handleCheckbox}
                value={checkValue}
                checked={checkValue}
              />
              <RememberText>Lembrar-me</RememberText>
            </RememberContainer>

            {/* <ForgotPasswordText to="/forgotpassword">
              Esqueci a senha
            </ForgotPasswordText> */}
          </LoginOptions>
        </InputsContainer>

        <LoginButton type="button" onClick={handleSendLogin}>
          ENTRAR
        </LoginButton>
      </RightMain>
    </Container>
  );
};

export default Login;
