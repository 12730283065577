import React, { useState } from 'react';
import api from '../../../services/api';
import { Cam } from '../styles';
import {
  SelectCameraContainer,
  ModalToAddCamera,
  ExternalModalToAddCamera,
} from './styles';

const CameraContainer: React.FC = () => {
  const [cameras, setCameras] = useState<{ id: string }[]>();

  const [selectedCamera, setSelectedCamera] = useState<number>();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [valueOfCameraId, setValueOfCameraId] = useState<number>();

  function handleOpenModal() {
    api.get(`cameras/`).then((response) => {
      setCameras(response.data);
      setValueOfCameraId(response.data[0].id);
      setOpenModal(true);
    });
  }

  return (
    <>
      {openModal && (
        <ExternalModalToAddCamera>
          <ModalToAddCamera>
            <select
              value={valueOfCameraId}
              onChange={(e: any) => setValueOfCameraId(e.currentTarget.value)}
            >
              {cameras?.map((item: any) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>

            <div
              style={{
                width: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
              }}
            >
              <button onClick={() => setOpenModal(false)}>Cancelar</button>

              <button
                onClick={() => {
                  setSelectedCamera(valueOfCameraId);
                  return setOpenModal(false);
                }}
              >
                Adicionar
              </button>
            </div>
          </ModalToAddCamera>
        </ExternalModalToAddCamera>
      )}
      {!selectedCamera ? (
        <SelectCameraContainer onClick={handleOpenModal}>
          +
        </SelectCameraContainer>
      ) : (
        <Cam
          src={`${window.location.host.includes('localhost') ? 'http://localhost:8000' : `https://${window.location.host}`}/api/video/${selectedCamera}/`}
          key={selectedCamera + Math.random()}
          alt="testando"
        />
      )}
    </>
  );
};

export default CameraContainer;
