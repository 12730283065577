import React, { useState } from 'react';
import { TiVideo } from 'react-icons/ti';
import {
  RiAlertLine,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiCloseFill,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Button from '../custom/Button';

import {
  Container,
  ButtonContainer,
  TextModal,
  Header,
  Title,
  Screen,
  SubTitle,
  Footer,
  Informations,
  Accuracy,
  NumberEvent,
  Weapon,
  Main,
  Closed,
} from './styles';

const Modal: React.FC<any> = ({ event, type, visible, setVisible }) => {
  let Icon;
  if (type === 'sucess') {
    Icon = <RiCheckboxCircleLine color="#238E58" size={64} />;
  } else if (type === 'warning') {
    Icon = <RiAlertLine color="#FF750B" size={64} />;
  } else if (type === 'error') {
    Icon = <RiCloseCircleLine color="#D34A4A" size={64} />;
  }

  return (
    <Container key={event.id} visible={visible}>
      <Closed onClick={setVisible}><RiCloseFill size={36} /></Closed>
      <Main>
        <Header>
          <Title>{`Câmera 1 ${event.type_weapon} Identificada`}</Title>
          <SubTitle>Localização: {event.place}</SubTitle>
        </Header>
        <TextModal>{event.text}</TextModal>
        <Screen src={event.picture} alt="testing" />
        <Footer>
          <Informations>
            <Weapon>Arma: {event.type_weapon}</Weapon>
            <NumberEvent>Número do evento: {event.mac_address}</NumberEvent>
            <Accuracy> Acurária: {event.accuracy}%</Accuracy>
          </Informations>
          <ButtonContainer>
            <Link to="/campage">
              <Button
                Icon={TiVideo}
                text="AO VIVO"
                color="#B50000"
                background="#060E22"
                className="button-save"
                clickFunction={() => setVisible()}
              />
            </Link>
          </ButtonContainer>
        </Footer>
      </Main>
    </Container>
  );
};

export default Modal;
