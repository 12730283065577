import React, { useEffect, useState } from 'react';
import { Cam, Container } from './styles';
import CommonStructurePage from '../CommonStructurePage/index';
import api from '../../services/api';

interface DevicesCamProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface IDevice {
  id: number;
  name: string;
  place: string;
  cameras: {
    id: number;
    ip: string;
    mac: string;
    camera: string;
    rtsp: string;
  }[];
  lat: string;
  lng: string;
}

const DevicesPage: React.FC<DevicesCamProps> = ({ match }) => {
  const [getDevice, setGetDevice] = useState<IDevice>();

  useEffect(() => {
    api.get(`devices/${match.params.id}/`).then((response) => {
      setGetDevice(response.data);
    });
  }, []);

  return (
    <CommonStructurePage>
      <Container>
        {getDevice &&
          getDevice.cameras.map((camera) => (
            <Cam
              src={`/api/video/${camera.id}`}
              alt="testando"
              key={camera.id}
            />
          ))}
      </Container>
    </CommonStructurePage>
  );
};

export default DevicesPage;
