/* eslint-disable no-use-before-define */
import React, { useEffect, useState, ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RiAddCircleLine, RiSearchLine } from 'react-icons/ri';
import ptLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { ExportToCsv } from 'export-to-csv';
import InputAdornment from '@material-ui/core/InputAdornment';
import api from '../../services/api';
import CommonStructurePage from '../CommonStructurePage/index';

import {
  Container,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  TitleContainer,
  SelectContainer,
  Select,
  Option,
} from './styles';
import Button from '../../components/custom/Button';
import EventTrBox from './EventTrBox';

interface EventProps {
  id: number;
  type_weapon: string;
  camera: string;
  place: string;
  accuracy: number;
  datetime: string;
}

const Events: React.FC = () => {
  const [dataState, setDataState] = useState<EventProps[]>();
  const [dataSelect, setDataSelect] = useState<any>();
  const [type, setType] = useState<string>('');
  const [typeAccuracy, setTypeAccuracy] = useState<string>('gte');
  const [accuracy, setAccuracy] = useState<any>('');
  const [initialDate, setInitialDate] = useState<any>(new Date());
  const [finalDate, setFinalDate] = useState<any>(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());

  useEffect(() => {
    api.get('filter-events?all=0').then((response) => {
      setDataState(response.data);
    });

    api.get('filter-events').then((response) => {
      setDataSelect(response.data.eventsTypes);
    });

    const datetest = new Date();

    console.log(datetest.toISOString());
  }, []);

  const handleFilterUsers = () => {
    if (type !== '') {
      let initDate = new Date(initialDate);
      let endDate = new Date(finalDate);

      api
        .get(
          `/filter-events?query=${type}&accuracy=${Number(
            accuracy
          )}&accuracy_type=${typeAccuracy}&initial=${initDate
            .toISOString()
            .replace('Z', '')}&end=${endDate.toISOString().replace('Z', '')}`
        )
        .then((response) => {
          setDataState(response.data);
        })
        .catch((err) => console.log(err));
    } else {
      api.get('/filter-events?all=0').then((response) => {
        setDataState(response.data);
      });
    }
  };

  const exportCsv = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'GunSystem',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(dataState);
  };

  return (
    <CommonStructurePage>
      <Container>
        <TitleContainer>
          <SelectContainer>
            <Select id="select-state" onChange={(e) => setType(e.target.value)}>
              <Option value="" selected>
                Todos
              </Option>
              {dataSelect &&
                dataSelect.map((item: any) => {
                  return <Option value={item}>{item}</Option>;
                })}
            </Select>
            <TextField
              id="standard-basic"
              value={accuracy}
              onChange={(e) => setAccuracy(e.currentTarget.value)}
              style={{ width: '300px' }}
              placeholder="Buscar por acurácia"
              className="MuiInput-root"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearchLine size={18} color="#808495" />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              id="select-state"
              value={typeAccuracy}
              onChange={(e) => setTypeAccuracy(e.target.value)}
            >
              <Option value="gte">Maior ou igual</Option>
              <Option value="lte">Menor ou igual</Option>
            </Select>
            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
              <DateTimePicker
                autoOk
                ampm={false}
                disableFuture
                placeholder="dd/mm/aaaa"
                value={initialDate}
                onChange={setInitialDate}
              />
              <DateTimePicker
                autoOk
                ampm={false}
                disableFuture
                placeholder="dd/mm/aaaa"
                value={finalDate}
                onChange={setFinalDate}
              />
            </MuiPickersUtilsProvider>
            <Button
              text="Filtrar"
              color="#fff"
              background="#1C3359"
              clickFunction={handleFilterUsers}
            />
            <Button
              text="Exportar CSV"
              color="#fff"
              background="#1C3359"
              clickFunction={exportCsv}
            />
          </SelectContainer>
        </TitleContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Tipo da arma</Th>
              <Th>Local</Th>
              <Th>Câmeras</Th>
              <Th>Acurária</Th>
              <Th>Data</Th>
            </Tr>
            {dataState && dataState.map((item) => <EventTrBox item={item} />)}
          </Thead>
        </Table>
      </Container>
    </CommonStructurePage>
  );
};

export default Events;
