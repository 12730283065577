import React, { useState } from 'react';
import { RiSaveLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import CommonStructurePage from '../../CommonStructurePage';

import {
  Container,
  Title,
  Descriptions,
  Input,
  FormData,
  WrapperInput,
  Wrapper,
  WrapperButtons,
  SubTitle,
  WrapperContainer,
} from './styles';

import Button from '../../../components/custom/Button';
import Modal from '../../../components/custom/Modal';

const EditUsers: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);


  const history = useHistory();

  const handleRegister = () => {
    const id = document.URL;
    const idUser = id.split('/')[4];

    const data = {
      email,
      name,
      password,
    };
    api
      .put(`authenticate/user/update/${idUser}/`, data)
      .then(() => setSucessRegister(true))
      .catch(() => setErroRegister(true));
  };

  return (
    <CommonStructurePage>
      <WrapperContainer>
      <Container>
        <Title>Editar usuário</Title>
        <SubTitle>Preencha os campos a seguir:</SubTitle>
        <FormData>
          <Wrapper>
            <WrapperInput>
              <Descriptions>Nome completo</Descriptions>
              <Input
                type="text"
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>E-mail</Descriptions>
              <Input
                type="text"
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Senha</Descriptions>
              <Input
                type="text"
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </WrapperInput>
          </Wrapper>
          <WrapperButtons>
            <Button
              text="Cancelar"
              color="#040E21"
              background="#fff"
              clickFunction={() => history.push('/registered-users')}
            />
            <Button
              text="Salvar"
              data-testid="handleregister"
              color="#fff"
              Icon={RiSaveLine}
              background="#040E21"
              clickFunction={handleRegister}
            />
          </WrapperButtons>
        </FormData>
        {sucessRegister ? (
          <Modal type="sucess" text="Usuário alterado com sucesso!">
            <Button
              text="OK"
              color="#FFFFFF"
              background="#040E21"
              className="button-cancel"
              clickFunction={() => setSucessRegister(false)}
            />
          </Modal>
        ) : (
          <></>
        )}
        {erroRegister ? (
          <Modal type="error" text="Falha ao alterar o usuário!">
            <Button
              text="OK"
              color="#FFFFFF"
              background="#040E21"
              className="button-cancel"
              clickFunction={() => setErroRegister(false)}
            />
          </Modal>
        ) : (
          <></>
        )}
      </Container>
      </WrapperContainer>
    </CommonStructurePage>
  );
};

export default EditUsers;
