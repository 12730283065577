import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #152744;

  padding: 40px;

  overflow-y: auto;

  .MuiInput-root {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    border-bottom: none;
    padding-left: 5px;
    display: flex;
    justify-content: center
  }

  .MuiInput-underline::before {
    border-bottom: #1C3359;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  
  justify-content: space-between;

  margin-bottom: 30px;

  @media (min-width: 980px) {
    width: 50%;
    flex-direction: row;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: space-between;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  @media (min-width:980px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const Title = styled.h5`
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
`;

export const SelectContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  margin-bottom: 10px;

  @media (min-width: 980px) {
    width: 60%;
    justify-content: flex-start;
    flex-direction: row;
    gap: 50px;
    margin-bottom: 0;
    margin-right: 10px;
  }
`;

export const Cards = styled.div`
  width: 100%;

  margin-top: 80px;

  display: grid;
  grid-template-columns: 1fr;

  gap: 20px;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
