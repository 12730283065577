import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 20px;
  margin-bottom: 10px;

  @media (min-width: 980px) {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
    grid-gap: 15px;
    justify-items: center;
    justify-content: center;
    overflow: hidden;
  }
`;

export const Cam = styled.img`
  width: 100%;

  @media (min-width: 768px) and (min-height: 1024px) {
    width: 80%;
  }

  @media (min-width: 980px) {
    width: 100%;
  }
`;
