import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  min-height: 200px;
`;

export const SelectCameraContainer = styled.div`
  width: 100%;
  height: 320px;

  border: 2px solid #fff;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 64px;

  cursor: pointer;
`;

export const ExternalModalToAddCamera = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;

  background: #0005;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalToAddCamera = styled.div`
  width: 50%;
  max-width: 200px;
  height: 200px;

  background-color: #011f4b;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  select {
    width: 80%;
    padding: 8px;
  }

  button {
    padding: 8px;
    border-radius: 2px;

    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
`;
