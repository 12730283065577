import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';

import api from '../../../../services/api';

import { ICams } from '../RegisteredCams';

import {
  Container,
  CardContent,
  CardHeader,
  InfoSections,
  Icons,
  TextSection,
  TitleSection,
  UserName,
  DevicesSections,
  DevicesText,
  DevicesTitle,
} from './styles';
import Modal from '../../../../components/custom/Modal';
import CustomButton from '../../../../components/custom/Button';

interface Props {
  cams: ICams;
}

const CamsCard: React.FC<Props> = ({ cams }) => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [sucessModal, setSucessModal] = useState<boolean>(false);
  const [hideCard, setHideCard] = useState<string>('flex');

  const history = useHistory();

  function openWarningDelete() {
    setConfirmModal(true);
  }

  function deletePlace(id: number) {
    setConfirmModal(false);
    api.delete(`cameras/${id}/`).then(() => {
      setHideCard('none');
      setSucessModal(true);
    }).catch((err) => console.log(err));
    
  }

  return (
    <>
      {confirmModal ? (
        <Modal text="Tem certeza que deseja excluir a câmera?" type="warning">
          <CustomButton
            text="Não excluir"
            clickFunction={() => setConfirmModal(false)}
            color="var(--white-text)"
            border="1px solid var(--primary-color)"
            background="#1C3359"
          />
          <CustomButton
            text="Excluir"
            clickFunction={() => deletePlace(cams.id)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      {sucessModal ? (
        <Modal text="Câmera excluído com sucesso!" type="sucess">
          <CustomButton
            text="OK"
            clickFunction={() => setSucessModal(false)}
            color="var(--white-text)"
            background="#1C3359"
          />
        </Modal>
      ) : (
        <></>
      )}
      <Container style={{ display: hideCard }}>
        <CardHeader>
          <UserName>{cams.name}</UserName>
          <Icons>
            <RiEdit2Line
              size={22}
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => {
                history.push(`/edit-cam/${cams.id}`);
              }}
            />
            <RiDeleteBin2Line
              size={22}
              data-testid="deleteuser"
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => openWarningDelete()}
            />
          </Icons>
        </CardHeader>
        <CardContent>
          <InfoSections>
            <TitleSection>Endereço Ip:</TitleSection>

            <TextSection>{cams.ip}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Endereço Mac:</TitleSection>

            <TextSection>{cams.mac}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Dispositivo:</TitleSection>

            <TextSection>{cams.device}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Local:</TitleSection>

            <TextSection>{cams.place}</TextSection>
          </InfoSections>
          <InfoSections>
            <TitleSection>Rtsp:</TitleSection>

            <TextSection>{cams.rtsp}</TextSection>
          </InfoSections>
        </CardContent>
      </Container>
    </>
  );
};

export default CamsCard;
