import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { Container, MapBox } from './styles';
import 'leaflet/dist/leaflet.css';
import api from '../../services/api';




let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

interface IPlace {
  key: number;
  id?: number;
  name: string;
  lat: number;
  lng: number;
}

const Map: React.FC = () => {
  const [locally, setLocally] = useState<IPlace[]>();
  // const getMapOptions = (maps: any) => {
  //   return {
  //     streetViewControl: true,
  //     scaleControl: true,
  //     mapTypeId: maps.MapTypeId.terrain,
  //   };
  // };

  useEffect(() => {
    api
      .get('places/')
      .then((response) => {
        setLocally(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {locally && (
        <Container className="map">
          <MapBox>
            <MapContainer center={[locally[0]?.lat, locally[0]?.lng]} zoom={locally.length > 1 ? 8 : 15}
              style={{width: '100%', height: '100%'}} >
              <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

              {/* <Marker position={[locally[0]?.lat, locally[0]?.lng]}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker> */}

              {locally.map(item => (
                <Marker position={[item.lat, item.lng]} key={item.key}>
                  <Popup>
                    <strong>{item.name}</strong> <br/><br/> {`Latitude: ${item.lat.toFixed(3)}`} <br/> {`Longitude: ${item.lng.toFixed(3)}`}
                  </Popup>
                </Marker>
              ))}

            </MapContainer>
          </MapBox>
        </Container>
      )}
    </>
  );
};
export default Map;
