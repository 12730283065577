import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 100%;
    padding: 12px 6px 14px 12px;

    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;

    color: var(--white-text);

    h3 {
      display: block;
      font-size: 16px;

      color: var(--white-text);
    }
  }
`;
