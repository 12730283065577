import React, { useEffect, useState } from 'react';
import Header from '../../components/custom/Header';

import { Container, Main } from './styles';
import Sidebar from '../../components/custom/Sidebar';
import Alert from '../../components/custom/Alert';
import EventModal from '../../components/AlertEvent/index';
import api from '../../services/api';

const CommonStructurePage: React.FC = ({ children }) => {
  const [sidebarExpand, setSidebarExpand] = useState(false);
  const [alertExpand, setAlertExpand] = useState(false);
  const [callEvent, setCallEvent] = useState(false);
  const [callCamPage, setCallCamPage] = useState(false);

  const setSidebarExpandedVerify = () => {
    setSidebarExpand(!sidebarExpand);
  };

  const setAlertExpandedVerify = () => {
    setAlertExpand(!alertExpand);
  };

  const setEventExpandedVerify = () => {
    setCallEvent(!callEvent);
  };

  const setCamPageExpandedVerify = () => {
    setCallCamPage(!callCamPage);
  };

  return (
    <>
      <Header
        setSidebarExpand={setSidebarExpandedVerify}
        setAlertExpand={setAlertExpandedVerify}
      />
      <Container>
        <Sidebar expand={sidebarExpand} />
        <Main>{children}</Main>
        <Alert setCallEvent={setEventExpandedVerify} expand={alertExpand} />
      </Container>
    </>
  );
};

export default CommonStructurePage;
