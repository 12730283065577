import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  background-color: #152744;

  padding: 30px;

  overflow-y: auto;
  overflow-x: auto;

  .MuiInput-root {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    border-bottom: none;
    padding-left: 5px;
    display: flex;
    justify-content: center
  }

  .MuiInput-underline::before {
    border-bottom: #1C3359;
  }

  .datepicker {
    min-width: 120px !important;
  }
  .timepicker {
    min-width: 80px !important;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  
  justify-content: space-between;

  margin-bottom: 30px;

  @media (min-width: 980px) {
    width: 100%;
    flex-direction: column;
  }
`;


export const SelectContainer = styled.div`
  width: 100%;
  height: auto;

  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  gap: 10px;
  
  margin-bottom: 10px;

  @media (min-width: 980px) {
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 0;
    margin-right: 10px;
  }
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  height: auto;

  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  gap: 10px;
  
  margin-bottom: 10px;

  @media (min-width: 980px) {
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 0;
    margin-right: 10px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: space-between;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  @media (min-width:980px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const Rows = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: #09172F;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  
  padding: 20px;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
`;

export const Tr = styled.tr`
  border: 1px solid black;
  
`;

export const Th = styled.th`
  border: 1px solid #fffbfb4f;
  padding: 0.5rem;
  text-align: left;
  height: 50px;

  background-color: #08152D;
  color: #fff;
`;

export const Td = styled.td`
  border: 1px solid #fffbfb4f;
  padding: 0.5rem;
  text-align: left;
  height: 50px;
  font-weight: 500;
  color: #fff;


  background-color: #0D2042;
`;

export const Select = styled.select`
  width: 90%;
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  border-bottom: none;

  margin-left: 0;

  @media (min-width: 980px) {
    width: 20%;
  }
`;

export const Option = styled.option`
`;
