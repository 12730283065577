/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { BiTrash } from 'react-icons/bi';

import {
  Container,
  AlertTitle,
  Wrapper,
} from './styles';
import api from '../../../services/api';

import AlertBoxItems from '../../AlertBoxItems';
import { useAuth } from '../../../hooks/AuthContext';

interface Ialertbar {
  setCallEvent: (state: boolean) => void;
  expand: boolean;
}

// interface EventProps {
//   id: number;
//   type_weapon: string;
//   mac_address: string;
//   lat: number;
//   lng: number;
//   accuracy: number;
//   ip_address: string;
//   cam_name: string;
//   datetime: string;
//   picture: string;
// }

const Alert: React.FC<Ialertbar> = ({ expand }) => {
  const { stateEvents, getEvents } = useAuth();

  const [allEventsToShow, setAllEventsToShow] = useState<any>();

  // const PORT = 8000;
  // const HOST = 'localhost';
  // const GROUP = 'user1';

  // const url = `ws://${HOST}:${PORT}/ws/events/${GROUP}/`;

  // const PORT = 5000;
  // const HOST = 'vigia.primeranalytics.com.br';

  const url = window.location.host.includes('localhost') ? `ws://localhost:5000/ws` : `wss://${window.location.host}/ws`; 
  
  const client = new WebSocket(url);

  // console.log(`Attempting connection to: '${url}'`);

  function addEventToList() {
    getEvents();
  }

  useEffect(() => {
    setAllEventsToShow(stateEvents);
  }, [stateEvents]);

  useEffect(() => {
    if (client) {
      client.onmessage = () => {
        // msg = JSON.parse(msg.data);
        // console.log(msg.data);

        addEventToList();
      };
    }
  }, [client]);

  const handleClear = () => {
    allEventsToShow?.map((event: any) => api.put(`events/`, { id: event.id }));

    setTimeout(() => getEvents(), 1000);
  };

  return (
    <Container expand={expand}>
      <Wrapper>
        <AlertTitle>Eventos</AlertTitle>
        <BiTrash
          size={25}
          color="white"
          style={{ cursor: 'pointer' }}
          onClick={handleClear}
        />
      </Wrapper>
      {allEventsToShow &&
        allEventsToShow.map((event: any) => (
          <AlertBoxItems key={event.id} event={event} />
        ))}
    </Container>
  );
};

export default Alert;
