import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './pages/Auth/Login';
import CamPage from './pages/CamPage';
import HomePage from './pages/HomePage';
import DevicesPage from './pages/DevicesPage';
import SpecifyCam from './pages/SpecifyCam';

import RegisteredUsers from './pages/Users/RegisteredUsers';
import RegisterUsers from './pages/Users/RegisterUsers';
import EditUsers from './pages/Users/EditUsers';
import RegisterPlace from './pages/Registers/Placers/RegisterPlace';
import RegisteredPlace from './pages/Registers/Placers/RegisteredPlace';
import RegistersMenu from './pages/Registers/RegistersMenu';
import EditPlace from './pages/Registers/Placers/EditPlace';
import Events from './pages/Events';
import RegisterDevices from './pages/Registers/Devices/RegisterDevices';
import RegisteredDevices from './pages/Registers/Devices/RegisteredDevices';
import EditDevices from './pages/Registers/Devices/EditDevices';
import PersistData from './pages/Registers/PersistData';

import { useAuth } from './hooks/AuthContext';
import RegisterCams from './pages/Registers/Cams/RegisterCams';
import RegisteredCams from './pages/Registers/Cams/RegisteredCams';
import EditCams from './pages/Registers/Cams/EditCams';

const Routes: React.FC = () => {
  const { getEvents } = useAuth();

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/home" component={HomePage} />
      <Route exact path="/campage" component={CamPage} />
      <Route exact path="/devices/:id" component={DevicesPage} />
      <Route exact path="/specify/:id" component={SpecifyCam} />
      <Route exact path="/registered-users" component={RegisteredUsers} />
      <Route exact path="/register-users" component={RegisterUsers} />
      <Route exact path="/edit-users/:id" component={EditUsers} />
      <Route exact path="/register-place" component={RegisterPlace} />
      <Route exact path="/registered-places" component={RegisteredPlace} />
      <Route exact path="/register-menu" component={RegistersMenu} />
      <Route exact path="/edit-place/:id" component={EditPlace} />
      <Route exact path="/register-devices" component={RegisterDevices} />
      <Route exact path="/register-cams" component={RegisterCams} />
      <Route exact path="/registered-devices" component={RegisteredDevices} />
      <Route exact path="/edit-device/:id" component={EditDevices} />
      <Route exact path="/registered-cams" component={RegisteredCams} />
      <Route exact path="/edit-cam/:id" component={EditCams} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/persist-data" component={PersistData} />
    </Switch>
  );
};

export default Routes;
