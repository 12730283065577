import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { AuthProvider, useAuth } from './hooks/AuthContext';

const App: React.FC = () => {
  const token = localStorage.getItem('@GUN:token');

  if (!token && window.location.pathname !== '/') {
    window.location.replace('/');
  }

  return (
    <>
      <Router>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
