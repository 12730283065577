import React, { useState, useEffect } from 'react';
import { RiSaveLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import CommonStructurePage from '../../../CommonStructurePage';

import {
  Container,
  Title,
  Descriptions,
  Input,
  FormData,
  WrapperInput,
  Wrapper,
  WrapperButtons,
  WrapperContainer,
  Select,
  Option
} from './styles';

import Button from '../../../../components/custom/Button';
import Modal from '../../../../components/custom/Modal';

const RegisterDevices: React.FC = () => {
  const [place, setPlace] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [sucessRegister, setSucessRegister] = useState<boolean>(false);
  const [erroRegister, setErroRegister] = useState<boolean>(false);
  const [dataSelect, setDataSelect] = useState<any>();


  const history = useHistory();

  useEffect(() => {
    api.get('places/').then(response => {
      setDataSelect(response.data)
      })
  }, [])

  const handleRegister = () => {
    const data = {
      name,
      place_id: place
    };
    api
      .post('devices/', data)
      .then(() => setSucessRegister(true))
      .catch(() => setErroRegister(true));
  };

  return (
    <CommonStructurePage>
      <WrapperContainer>
      <Container>
        <Title>Cadastrar um Dispositivo</Title>
        <Descriptions>Preencha os campos a seguir:</Descriptions>
        <FormData>
          <Wrapper>
            <WrapperInput>
              <Descriptions>Nome:</Descriptions>
              <Input
                type="text"
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </WrapperInput>
            <WrapperInput>
              <Descriptions>Place</Descriptions>
              <Select id="select-state" onChange={(e: any) => setPlace(e.target.value)}>
              <Option value="0" selected>
                Choose here
              </Option>
              {dataSelect &&
                dataSelect.map((item: any) => {
                  return (
                    <Option value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
            </WrapperInput>
          </Wrapper>
          <WrapperButtons>
            <Button
              text="Cancelar"
              color="#040E21"
              background="#fff"
              clickFunction={() => history.push('/registered-devices')}
            />
            <Button
              text="Salvar"
              color="#fff"
              Icon={RiSaveLine}
              background="#040E21"
              clickFunction={handleRegister}
            />
          </WrapperButtons>
        </FormData>
        {sucessRegister ? (
          <Modal type="sucess" text="Dispositivo cadastrado com sucesso!">
            <Button
              text="OK"
              color="#FFFFFF"
              background="#040E21"
              className="button-cancel"
              clickFunction={() => {
                setSucessRegister(false);
                history.push('/registered-devices');
              }}
            />
          </Modal>
        ) : (
          <></>
        )}
        {erroRegister ? (
          <Modal type="error" text="Falha ao cadastrar Dispositivo!">
            <Button
              text="OK"
              color="#FFFFFF"
              background="#1C3359"
              className="button-cancel"
              clickFunction={() => setErroRegister(false)}
            />
          </Modal>
        ) : (
          <></>
        )}
      </Container>
      </WrapperContainer>
    </CommonStructurePage>
  );
};

export default RegisterDevices;
